.quoteform {
    padding-top: 160px;
    padding-bottom: 110px;

    &__content {
        column-gap: 40px;
    }

    &__title {
        font-family: 'Poppins';
        font-style: italic;
        font-weight: 700;
        font-size: 40px;
        line-height: 44px;
        display: flex;
        align-items: center;
        background: linear-gradient(180deg, #F5B848 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        -webkit-text-stroke: 1px black; /* Толщина и цвет обводки */
        text-stroke: 1px black; /* Толщина и цвет обводки (стандартное свойство) */
        
    }

    &__sm-title {
        margin: 30px 0 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        display: flex;
        align-items: center;
        color: var(--black);
    }

    &__div {
        margin-bottom: 30px;
    }

    &__lab {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: var(--black);
    }

    &__inp {
        width: 100%;
        height: 30px;

        &-big {
            height: 90px;
        }
    }

    &__submit {
        background: var(--d);
        border-radius: 4px;
        height: 34px;
        width: 180px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: var(--black);
        transition: 0.3s;
        align-self: flex-end;

        &:hover {
            background: var(--white);
        }
    }

    &__immediate {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: var(--black);
    }
}


@media (max-width: 650px) {
    .quoteform {
        padding-top: 80px;
        &__content {
            grid-template-columns: 1fr;
        }
    }
}


@media (max-width: 500px) {
    .quoteform {
      &__lab {
        font-size: 12px;
      }
      &__sm-title {
        font-size: 18px;
      }
    }
}