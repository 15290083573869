.heroservices {
    background: url(../../../assets/images/ServicesPage/truck.jpg) no-repeat center/cover;
    width: 100%;
    height: 100vh;
    position: relative;

    &::after {
        position: absolute;
        content: "";
        clip-path: polygon(0 0, 80% 0%, 100% 100%, 0% 100%);
        background: rgba(0, 0, 0, 0.8);
        left: 0;
        bottom: 0;
        width: 55%;
        height: 100%;
    }

    &__title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 76px;
        line-height: 78px;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 1;
        margin: 100px 50px 0 100px;
    }
}


@media (max-width: 600px) {
    .heroservices {
        &__title {
            font-size: 40px;
            margin: 100px 50px 0 20px;
        }
    }
}