// @media (min-width: 1440px) {
//   html {
//     zoom: 1.5;
//   }
// }

// @media (min-width: 2560px) {
//   html {
//     zoom: 1.7;
//   }
// }

// @media (min-width: 3860px) {
//   html {
//     zoom: 2.5;
//   }
// }








.myswiper {
  background: rgb(23, 18, 0);

}

.swiper {
  height: 425px;
}



.container {
  position: relative;
  max-width: 124rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper_container {
  height: 52rem;
  padding: 2rem 0;
  position: relative;

}

.swiper-slide {
  width: 52rem;
  height: 53rem;
  position: relative;
}



.swiper-slide img {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  object-fit: cover;
  object-position: center;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: absolute;
  bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1111;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}



.slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: -1rem;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.swiper-pagination .swiper-pagination-bullet {
  // filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  border: 1px solid #D4AF6C;
  width: 12px;
  height: 12px;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--white);
}


.swiper-button-prev::after {
  color: var(--f)
}

.swiper-button-next::after {
  color: var(--f)
}





@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}


@media (max-width: 600px) {
  .swiper {
    height: 200px;
  }

  .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
}



@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}