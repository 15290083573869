.contact__button {

    border: 1px solid var(--d);
    border-radius: 100px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--white);
    width: 209px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(180deg, #F5B848 0%, #BBA884 100%);
    border: 1px solid var(--d);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    transition: 0.3s;

    &:hover {
        background: var(--white);
        border: 1px solid var(--white);
        color: var(--f);
    }


}