.header {
    position: absolute;
    height: 83px;
    top: 0;
    background: rgba(84, 83, 82, 0.63);
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;


    &__link:not(:first-child) {
        margin-left: 50px;
    }

    &__link {
        position: relative;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: var(--white);
        flex: none;
        order: 1;
        flex-grow: 0;
        transition: 0.3s;

        &.canHover::after {
            position: absolute;
            content: "";
            bottom: -5px;
            left: 50%;
            height: 2px;
            background-color: var(--f);
            width: 0%;
            transition: 0.3s;
            transform: translateX(-50%);
        }

        &.canHover:hover {
            color: var(--f);
        }

        &.canHover:hover::after {
            width: 110%;
        }
    }

    &__button {
        border: 1px solid var(--d);
        border-radius: 100px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--white);
        width: 209px;
        height: 40px;
        text-align: center;
        cursor: pointer;


    }

    &__burger {
        display: none;
      
    }

}





//ADAPTIVE

@media (max-width: 1050px) {
    .header {

        padding: 0 15px;
        background: var(--black);

        &__nav {
            display: none;
        }

        & .burger {
            display: block;
            position: relative;
            width: 35px;
            border: 2px solid #F5B848;
            border-radius: 10px;
            z-index: 1000;
            transition: 0.3s;


            &__nav {
                height: 100%;
            }
            &__divforabsoulte {
                position: relative;
            }
            &__link {
                position: relative;
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: var(--white);
                flex: none;
                order: 1;
                flex-grow: 0;
                transition: 0.3s;

                &.canHover:hover {
                    color: var(--f);
                }
            }

            &__click {
                position: absolute;
                width: 50px;
                height: 50px;
                top: -25px;
                left: -4px;
                ;

            }

            // &__link:not(:last-child) {
            //     margin-bottom: 15px;
            // }
            &::before {
                position: absolute;
                content: "";
                left: -2px;
                top: -12px;
                width: 100%;
                border: 2px solid #F5B848;
                border-radius: 10px;
                transition: 0.3s;
            }

            &::after {
                position: absolute;
                content: "";
                left: -2px;
                bottom: -12px;
                width: 100%;
                border: 2px solid #F5B848;
                border-radius: 10px;
                transition: 0.3s;
            }

            &__menu {
                transition: 0.8s;
                position: absolute;
                left: 0;
                width: 100%;
                height: 32vh;
                background: rgba(95, 81, 56, 100);
                padding: 40px 0;
            }
        }
    }

    .active.burger {
        transform: rotate(45deg);

        &::after {
            transform: rotate(-90deg);
            bottom: -2px;
        }

        &::before {
            display: none;
        }

    }
    
}

@media (max-width: 500px) {
    .header {
        &__logo {
            img {
                width: 62px;
            }
        }
      

        & .burger {
            
            width: 30px;


            &__click {
                position: absolute;
                width: 40px;
                height: 40px;
                top: -20px;
                left: -6px;
              
            }
            &__menu {
                background: rgba(95, 81, 56, 100);
            }
            // &__link:not(:last-child) {
            //     margin-bottom: 15px;
            // }
            &::before {
                position: absolute;
                content: "";
                left: -2px;
                top: -10px;
                width: 100%;
                border: 2px solid #F5B848;
                border-radius: 10px;
                transition: 0.3s;
            }

            &::after {
                position: absolute;
                content: "";
                left: -2px;
                bottom: -10px;
                width: 100%;
                border: 2px solid #F5B848;
                border-radius: 10px;
                transition: 0.3s;
            }
        }
    }

    .active.burger {
        transform: rotate(45deg);

        &::after {
            transform: rotate(90deg);
            bottom: -2px;
        }

        &::before {
            display: none;
        }
    }
}


