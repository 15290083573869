.getintouch {
    background: #5F5138;
    width: 100%;

    &__title {
        margin: 19px 0 25px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--white);

    }

    &__button {
        margin-bottom: 46px;
    }
}