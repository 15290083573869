.servicesadvantage{
    padding: 88px 0 120px; 
    background: var(--black);
    position: relative;
    &::after {
        background: url(../../../assets/images/map.png) no-repeat center/cover;
       
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    &__cards {
        grid-template-columns: 1fr;
        row-gap: 34px;
    }
    & .card {
        z-index: 1;
        background: var(--white);
        box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.07);
        border-radius: 30px;
        width: 334px;
        height: 334px;

        &__title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.1px;
            color: var(--black);
        }

        &__desc {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #737373;
            margin: 16px 0 10px;
        }
    }

}

@media (max-width: 600px) {
    .servicesadvantage {
        & .card {
            padding: 5px;
            width: 250px;
            height: 250px;
            &__title {
                font-size: 14px;
            }
            &__desc {
                font-size: 12px;
            }
        }
     
    }
}