.quotecontact {
    background: #5F5138;
    width: 100%;
    padding: 35px 0 41px;

    &__title {
        margin: 0 0 25px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--white);
        display: none;

    }

    &__desc {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--white);
        margin-top: 25px;
    }

    &__link {
        color: var(--f);
        margin-right: 5px;
        margin-left: 5px;
    }
}


@media (max-width: 700px) {
    .quotecontact {
        &__title {
            display: block;
        }

        &__link {
            display: none;
        }

        &__desc {
            display: none;
        }
    }
}