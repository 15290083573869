@import './reset.scss';
@import './fonts.scss';
@import './style.scss';


.navbarToSticky {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 350;
}


