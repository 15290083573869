.getquote {
    background: linear-gradient(180deg, #F5B848 0%, #BBA884 100%);
    border: 1px solid #D4AF6C;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    border: 1px solid #846D45;
    width: 190px;
    height: 46px;
    cursor: pointer;
    &:hover {
        background: var(--white);
        border: 1px solid var(--white);
        color: var(--f);
        width: 187px;
    }
}