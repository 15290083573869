.servicesdesc {
    width: 100%;
    background: var(--f);
    padding: 126px 0; 
    &__title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 49px;
        color: var(--black);
        max-width: 700px;
    }

    &__desc {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 157.4%;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: var(--white);
        margin-top: 35px;
    }

}


@media (max-width: 1200px) {
    .servicesdesc {
        & .container {
            padding: 0 50px;
        }
    }
}

@media (max-width: 500px) {
    .servicesdesc {
        & .container {
            padding: 0 25px;
        }
      &__desc {
        font-size: 14px;
      }
      &__title {
        font-size: 25px;
        line-height: 120%;
      }
    }
}