.herocontact {
    background: var(--black);
    padding: 198px 0 76px;
    position: relative;

    &::after {
        background: url(../../../assets/images/map.png) no-repeat center/cover;
        position: absolute;
        content: "";

        bottom: 0;
        right: 0;
        left: 0;
        margin: 0 auto;
        width: 100%;
        height: 100%;
    }

    &__form {
        z-index: 1;
    }

    &__title {
        font-family: 'Be Vietnam Pro';
        font-style: normal;
        font-weight: 800;
        font-size: 35px;
        line-height: 51px;
        color: var(--white);


    }

    &__desc {
        margin: 10px 0 50px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        display: flex;
        align-items: center;
        text-align: center;
        color: rgba(255, 255, 255, 0.44);
    }

    &__main {
        row-gap: 20px;
        column-gap: 32px;
        margin-bottom: 20px;

    }

    &__label {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: var(--white);
    }

    &__input {
        background: var(--white);
        border: 1px solid #757575;
        width: 338px;
        height: 38px;
        padding: 0 5px;
    }

    &__div {
        margin-bottom: 20px;
    }

    &__message {
        background: var(--white);
        border: 1px solid #757575;
        width: 708px;
        height: 58px;
        padding: 0 5px;
    }

    &__agree {
        display: none;
        margin: 40px 0;
    }

    &__check {
        background: #FFFFFF;
        border: 1px solid #767676;
        border-radius: 2.5px;
        margin-right: 8px;
    }

    &__agree-desc {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: var(--white);
    }
    &__agree-yellow{
        color: var(--f);
        margin: 0 5px;
    }
    &__submit {
        background: var(--d);
        border-radius: 4px;
        height: 34px;
        width: 180px;
        margin-top: 40px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: var(--black);
        transition: 0.3s;

        &:hover {
            background: var(--white);
        }
    }
}


@media (max-width: 800px) {
    .herocontact {
        &__main {
            grid-template-columns: 1fr;
        }
        &__form {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__message {
            width: 340px;
        }
    }
}

@media (max-width: 600px) {
    .herocontact {
        &__title {
            font-size: 30px;
            text-align: center;
        }
        &__label {
            font-size: 12px;
        }
    }
}

@media (max-width: 370px) {
    .herocontact {
      &__message {
            width: 300px;
        }
        &__input {
            width: 300px;
        }
    }
}
@media (max-width: 320px) {
    .herocontact {
      &__message {
            width: 280px;
        }
        &__input {
            width: 280px;
        }
    }
}

@media (max-width: 300px) {
    .herocontact {
      &__message {
            width: 260px;
        }
        &__input {
            width: 260px;
        }
    }
}