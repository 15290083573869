@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('../fonts/BeVietnamPro-ExtraBold.eot');
    src: url('../fonts/BeVietnamPro-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BeVietnamPro-ExtraBold.woff2') format('woff2'),
        url('../fonts/BeVietnamPro-ExtraBold.woff') format('woff'),
        url('../fonts/BeVietnamPro-ExtraBold.ttf') format('truetype'),
        url('../fonts/BeVietnamPro-ExtraBold.svg#BeVietnamPro-ExtraBold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}





@font-face {
    font-family: 'Nunito';
    src: url('../fonts/Nunito-Regular.eot');
    src: url('../fonts/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Nunito-Regular.woff2') format('woff2'),
        url('../fonts/Nunito-Regular.woff') format('woff'),
        url('../fonts/Nunito-Regular.ttf') format('truetype'),
        url('../fonts/Nunito-Regular.svg#Nunito-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('../fonts/Nunito-SemiBold.eot');
    src: url('../fonts/Nunito-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Nunito-SemiBold.woff2') format('woff2'),
        url('../fonts/Nunito-SemiBold.woff') format('woff'),
        url('../fonts/Nunito-SemiBold.ttf') format('truetype'),
        url('../fonts/Nunito-SemiBold.svg#Nunito-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('../fonts/Nunito-Medium.eot');
    src: url('../fonts/Nunito-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Nunito-Medium.woff2') format('woff2'),
        url('../fonts/Nunito-Medium.woff') format('woff'),
        url('../fonts/Nunito-Medium.ttf') format('truetype'),
        url('../fonts/Nunito-Medium.svg#Nunito-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('../fonts/Nunito-Bold.eot');
    src: url('../fonts/Nunito-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Nunito-Bold.woff2') format('woff2'),
        url('../fonts/Nunito-Bold.woff') format('woff'),
        url('../fonts/Nunito-Bold.ttf') format('truetype'),
        url('../fonts/Nunito-Bold.svg#Nunito-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}






@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Medium.eot');
    src: url('../fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Medium.woff2') format('woff2'),
        url('../fonts/Montserrat-Medium.woff') format('woff'),
        url('../fonts/Montserrat-Medium.ttf') format('truetype'),
        url('../fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.eot');
    src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/Montserrat-Regular.woff') format('woff'),
        url('../fonts/Montserrat-Regular.ttf') format('truetype'),
        url('../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.eot');
    src: url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/Montserrat-SemiBold.woff') format('woff'),
        url('../fonts/Montserrat-SemiBold.ttf') format('truetype'),
        url('../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.eot');
    src: url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/Montserrat-Bold.woff') format('woff'),
        url('../fonts/Montserrat-Bold.ttf') format('truetype'),
        url('../fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Black.eot');
    src: url('../fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Black.woff2') format('woff2'),
        url('../fonts/Montserrat-Black.woff') format('woff'),
        url('../fonts/Montserrat-Black.ttf') format('truetype'),
        url('../fonts/Montserrat-Black.svg#Montserrat-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}





@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.eot');
    src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular.ttf') format('truetype'),
        url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}









@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-ExtraBold.eot');
    src: url('../fonts/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-ExtraBold.woff2') format('woff2'),
        url('../fonts/Poppins-ExtraBold.woff') format('woff'),
        url('../fonts/Poppins-ExtraBold.ttf') format('truetype'),
        url('../fonts/Poppins-ExtraBold.svg#Poppins-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Medium.eot');
    src: url('../fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins-Medium.woff') format('woff'),
        url('../fonts/Poppins-Medium.ttf') format('truetype'),
        url('../fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Bold.eot');
    src: url('../fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/Poppins-Bold.woff') format('woff'),
        url('../fonts/Poppins-Bold.ttf') format('truetype'),
        url('../fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-SemiBold.eot');
    src: url('../fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/Poppins-SemiBold.ttf') format('truetype'),
        url('../fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Regular.eot');
    src: url('../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/Poppins-Regular.woff') format('woff'),
        url('../fonts/Poppins-Regular.ttf') format('truetype'),
        url('../fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}



